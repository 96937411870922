import { IABTest, isABTestActiveVariant } from 'ab_testing'
import { useParams, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'

export default function useABTestVariant(abTest: IABTest, variantId: number, abTestIdsCookie?: string): boolean {
	const pathname = usePathname()
	const params = useParams()

	const [isTestVariantActive, setIsTestVariantActive] = useState<boolean>(isABTestActiveVariant(params, abTest, variantId, abTestIdsCookie))

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setIsTestVariantActive(isABTestActiveVariant(params, abTest, variantId))
		}
	}, [params, pathname, setIsTestVariantActive, abTest, variantId])

	return isTestVariantActive
}

// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
// utm_source=facebook&utm_medium=cpm&utm_campaign={{campaign.name}}&nbt=nb%3Afb%3A{{site_source_name}}%3A{{campaign.id}}%3A{{adset.id}}%3A{{ad.id}}&nb_placement={{placement}}
